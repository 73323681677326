.home_sec1_main{
    width: 100%;
}
.main_div_of_website{
    min-height: 90vh;
    display: flex;
    flex-direction: column;
    padding-bottom: 120px;

    justify-content: space-around;

}
.homesec1_data{
    box-sizing: border-box;
    max-width: 1280px;
    margin:0px auto ;
     margin-top: 110px;
     padding: 0px 60px;
     overflow-x: hidden;
}
.homesec1_data h5 {
font-size: 46px;
font-weight: 700;
max-width: 520px;
margin-bottom: 0px ;
line-height: 40px;


}
.homesec1_data h6{
padding-top: 30px;
border-bottom: 0px;

}
.arrow_div{
    background-color: black;
    width: max-content;
    border-radius: 20px;
    padding: 10px 2px;
    margin: 0px 0px 0px 0px;
}
.arrow_div img{
   height:50px;
}
.homesec1_2nd_text{
    display: flex;
    justify-content: space-between;
    margin: 40px 0px;
    align-items: start;
    padding-top: 30px;
   

}
.text_challenge{
    width: 60%;
    padding: 0px 30px;
    
}
.text_challenge h5{
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 0px;
    max-width: 100%;

}
.text_challenge p{
    color:  #6d6565;
    font-weight: 700;
    font-size: 24px;
    max-width: 450px;
    padding: 60px 0px;

}
.box_dev{
    display: flex;
    align-items: center;
    
}
.box_dev h4{
margin-bottom: 0px;
background-color: yellow;
height: 40px;
border-radius: 20px;
padding: 6px 20px;
font-size: 17px;
display: flex;
align-items: center;
justify-content: center;

}
.box_dev img{
background-color: yellow;
height: 40px;
display: flex;
align-items: center;
justify-content: center;
border-radius: 60px;
width: 40px;
padding: 0px 10px ;
}
.newimg_add{
    max-width: 600px;
    margin: 0px;
    margin-left: auto;
    width: 100%;
    height: 220px;
   
}
.newimg_add img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 120px;
}

@media only screen and (max-width: 791px){

    .newimg_add{
        max-width: 600px;
        margin: 0px;
        margin-left: auto;
        width: 100%;
        height: 150px;
        margin-right: -70px;
       
    }
    .newimg_add img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 40px;
    }
        .homesec1_data{
     
         padding: 0px 30px;
    }
    .homesec1_2nd_text{
        flex-direction: column;
    }
    .mob_sec_view{
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;
    }
    .text_challenge{
width: 100%;
padding: 30px 0px;
    }
    .text_challenge h5{
        font-family: 28px;
        max-width: 420px;
    }
    .main_div_of_website{
        height: 80vh;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        padding-bottom: 0px;

     
    }
    .homesec1_data h5{
        font-size: 40px;
        line-height: 40px;
        max-width: 300px;
    }
   
}