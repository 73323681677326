* {

  font-style: normal;
}
 body{
  margin: 0px;
  font-family: 'Sen', sans-serif;
  padding: 0px;
  box-sizing: border-box;
  overflow-x: hidden;
 }



.slick-prev, .slick-next{
display: none !important;
}
.slick-prev:before, .slick-next:before{
  display: none !important;
}
.active_class{
  text-decoration: underline !important;
}




