.about_team_sec{
    background-color: #D8D8D8;
    box-sizing:border-box;
    overflow-x:hidden;
}
.slider_controls{
    width: 100%;
    display: flex;
    justify-content: center;
    color: #D8D8D8;
    align-items: center;
  

}
.slider_controls1{
    width: 100%;
    display: flex;
    justify-content: center;
    color: black;
    align-items: center;
  

}
.slider_controls button{
    border-radius: 120px;
    border: none;
    width: 60px;
    height: 60px;
    margin: 0px 10px;
}
.slider_controls1 button{
    border-radius: 120px;
    border: none;
    width: 60px;
    height: 60px;
    margin: 0px 10px;
}
.first_arrow {
    transform: rotate(180deg);
  }
.slick-next{
    display: none !important;
}
.slick-prev{
    display: none !important;
}
.about_team_data{
    max-width: 1280px;
    width: 100%;
    margin: 0px auto;
    padding: 30px 60px;
    box-sizing:border-box;
}

.team_haeding_div h6{
font-size: 130px;
font-weight: 700;
}
.team_haeding_div p{
    font-size: 12px;
    line-height: 14px ;
    max-width: 300px;
    font-weight: 400;
    padding-left: 20px;
    padding-right: 40px;
}
.team_member_section{
    padding: 70px 0px;
}
.team_intro{
    display: flex;
    justify-content:space-between;
    padding: 50px 0px;
}
.team_intro h6{
width: 40%;
padding-right: 30px;
font-size: 20px;
font-weight: 600;
}
.team_intro p{
font-weight: 600;
font-size: 15px;
   width: 100%;
    
    }
@media only screen and (max-width:791px){
    .about_team_data{
        padding: 30px 30px;
    }
    .team_haeding_div h6{
        font-size: 90px;
        font-weight: 700;
        color: white;
        }
        .team_haeding_div p{
            padding-left: 0px;
            padding-right: 0px;
            color: white;
            padding: 30px 0px;
            line-height: 25px;
        }
        .about_team_sec{
            
            background-color: #2A2A2A;;
            
        }
        .team_intro h6{
        color: white;
            }
            .team_intro p{
                color: white;
                
                }
                .team_intro{
               display: flex;
               flex-direction: column;
                }
                .team_intro h6{
                    font-size: 40px;
                    font-weight: 700;
                    width: 100%;
                    text-transform: capitalizes;
                    padding: 20px 0px;
                }
                .team_intro p{
                  font-size: 18px;
                  word-spacing: 0.25rem;
                  width: 90%;
                }
                .team_member_section{
padding: 30px 0px;
                }
}
@media only screen and (max-width:600px){
   
    .team_haeding_div h6{
        font-size: 70px;
        font-weight: 700;
        }
}
@media only screen and (max-width:420px){
   
    .team_haeding_div h6{
        font-size: 50px;
        font-weight: 700;
        }
}
