.home_third_data{
    max-width: 1280px;
    width: 100%;
    padding: 40px 60px;
    margin: 0px auto;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
}
.logo_sec{
    max-width: 1280px;
    width: 100%;
    margin: 0px auto;
    padding: 40px 60px;
    box-sizing: border-box;    
}
.logo_img_sec{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 15px;
    border-left: 1px solid #AEAEAE;
    border-right: 1px solid #AEAEAE;
    border-bottom: 1px solid #AEAEAE;
    width: 20%;
    height: 100px;
}
.logo_img_sec P{
font-size: 18px;
color: black;
font-weight: 600;

}

.parallel_section{
    display: flex;
    width: 65%;
}
.parallel_text{
width: 60%;
}
.parallet_imgs{
width: 40%;
}
.parallet_imgs img{
width: 100%;
height: 100%;
}
.doing_section{
    width: 30%;
}
.parallel_text p {
font-size: 12px;
font-weight: 400;
max-width:120px;
}
.parallel_text h6 {
font-size: 100px;
font-weight: 700;
word-wrap: break-word;
max-width: 310px;
line-height:80px ;

}
.parallel_text span{
 width: 40px;
 height: 40px;
 border-radius: 60px;
 display: flex;
 align-items: center;
 justify-content: center;
 padding: 5px 5px;
 background-color: yellow;
 margin: 30px 0px;
}
.doing_section span{
    width: 40px;
 height: 40px;
 border-radius: 60px;
 display: flex;
 align-items: center;
 justify-content: center;
 padding: 5px 5px;
 background-color: yellow;
 margin: 30px 0px;
}
.doing_section img{
width: 30px;
height: 30px;
}
.doing_section h6{
    font-size: 12px;
    font-weight: 400;
    max-width:100px;
    padding: 40px 0px;
}
.doing_section p{
    font-size: 15px;
    font-weight: 400;
    max-width:270px;
}


@media only screen and (max-width:791px){
    .home_third_data{
       flex-direction:column;
    }
    .parallel_section{
        width: 100%;
        flex-direction: column;
    }
    .doing_section{
        width: 100%;
    }
    .doing_section {
        margin-top: -125px;
    }
        
    .doing_section span{
     
     margin: 0px 0px 30px 0px;
    }
    .parallel_text h6{
        max-width: 100%;
        font-size: 70px;
        line-height: 70px;
        word-wrap: normal;
    }
    .parallet_imgs{
width: 100%;
    
}
.parallet_imgs img{ 
    margin: 30px 0px 0px 0px;
}
.doing_section span img{
    width: 20px;
    height: 20px;
    padding-right: 0px;
    }
.doing_section img{
width: 120px;
height: 60px;
padding-right: 0px;
}
.doing_section h6{
padding: 20px 0px;
display: flex;
align-items: center;
max-width: 320px;
width: 100%;
font-size: 26px;
line-height: 36px;
font-weight: 400;

}
.home_third_data{
   
    padding: 40px 30px;
  
}
.logo_sec{
 
    padding: 40px 30px;

}
}
@media only screen and (max-width:420px){
  
    .parallel_text h6{
        max-width: 100%;
        font-size: 50px;
        line-height: 40px;
        word-wrap: normal;
    }
}